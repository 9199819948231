import type { ItemReference, SearchParams } from "~/types/drupal_jsonapi";
import { For, Show } from "solid-js";
import Card from "../Cards/Card";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { useEventsContext } from "~/contexts/EventsContext";

import "~/components/Cards/CardPromo.css";

type Props = {
  programs: ItemReference[];
  showPromoCard?: boolean;
};

export default function ProgramsList(props: Props) {
  const programs = () =>
    props.showPromoCard && props.programs.length > 5
      ? props.programs.slice(0, -1)
      : props.programs;

  return (
    <>
      <For each={programs()}>
        {(program, i) => (
          <>
            {props.showPromoCard && i() === 2 && <PromoCard />}
            <Card
              nid={program.id}
              item={{
                id: program.id,
                title: program.title,
                url: program.url,
              }}
            />
          </>
        )}
      </For>
      <Show when={props.showPromoCard && props.programs.length < 3}>
        <PromoCard />
      </Show>
    </>
  );
}

export function ProgramsListSearchResults(
  props: Props & { searchParams: SearchParams },
) {
  return (
    <>
      <For each={props.programs}>
        {(program, i) => (
          <>
            {props.showPromoCard && i() === 2 && <PromoCard />}
            <Card
              nid={program.id}
              item={{
                id: program.id,
                title: program.title,
                url: program.url,
              }}
              searchParams={props.searchParams}
            />
          </>
        )}
      </For>
      <Show when={props.showPromoCard && props.programs.length === 1}>
        <PromoCard />
      </Show>
    </>
  );
}

function PromoCard() {
  const settings = useDrupalSettingsContext();
  const [, { sendClick }] = useEventsContext();

  return (
    <div
      class="cog-card-promo"
      classList={{
        "with-custom-img": settings.promo_card.img !== null,
      }}
    >
      <picture class="visual">
        <Show
          when={settings.promo_card.img !== null}
          fallback={<img src="/images/parkings-promo-bg.jpg" alt="" />}
        >
          <img src={settings.promo_card.img} alt="" />
        </Show>
      </picture>
      <h2>{settings.promo_card.title}</h2>
      <div innerHTML={settings.promo_card.body} />
      <Show when={settings.promo_card.url}>
        <a
          href={settings.promo_card.url}
          onClick={() => sendClick("card-promo", "list")}
          class="promo-btn-full"
        />
      </Show>
    </div>
  );
}
